body::-webkit-scrollbar, .custom-scrollbar::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}
body::-webkit-scrollbar-track, .custom-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}
body::-webkit-scrollbar-thumb, .custom-scrollbar::-webkit-scrollbar-thumb {
  background: #B71392;
  border-radius: 10px;
}
body::-webkit-scrollbar-thumbhover, .custom-scrollbar::-webkit-scrollbar-thumbhover {
  background: transparent;
}

.taxIDLinkDiv {
  display: inline-block;
  width: 100%;
}

.taxIDLinkDiv span {
  float: left;
  margin-right: 5px;
}

.taxIDLinkDiv .link_icon {
  float: left;
  margin-right: 5px;
  cursor: pointer;
}

.taxIDLinkDiv .link_icon a {
  color: #000;
}

.taxIDLinkDiv .copy_link {
  float: left;
  cursor: pointer;
}