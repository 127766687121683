@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?oqfj0y');
  src:  url('fonts/icomoon.eot?oqfj0y#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?oqfj0y') format('truetype'),
    url('fonts/icomoon.woff?oqfj0y') format('woff'),
    url('fonts/icomoon.svg?oqfj0y#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-copy:before {
  content: "\e91a";
}
.icon-circle-plus:before {
  content: "\e917";
}
.icon-circle-sub:before {
  content: "\e918";
}
.icon-filter:before {
  content: "\e915";
}
.icon-search:before {
  content: "\e916";
}
.icon-arrow-left:before {
  content: "\e911";
}
.icon-arrow-right:before {
  content: "\e912";
}
.icon-arrow-down:before {
  content: "\e913";
}
.icon-arrow-up:before {
  content: "\e914";
}
.icon-eye:before {
  content: "\e910";
}
.icon-triangle-left:before {
  content: "\e92c";
}
.icon-triangle-right:before {
  content: "\e92d";
}
.icon-triangle-up:before {
  content: "\e92e";
}
.icon-triangle-down:before {
  content: "\e92f";
}
.icon-house-shop:before {
  content: "\e90e";
}
.icon-closed-box:before {
  content: "\e90f";
}
.icon-insert-card:before {
  content: "\e906";
}
.icon-note-board:before {
  content: "\e907";
}
.icon-wallet:before {
  content: "\e908";
}
.icon-layout:before {
  content: "\e909";
}
.icon-settings:before {
  content: "\e90a";
}
.icon-payouts:before {
  content: "\f53a";
}
.icon-coupon:before {
  content: "\e90d";
}
.icon-circle-check:before {
  content: "\e902";
}
.icon-circle-close:before {
  content: "\e903";
}
.icon-circle-info:before {
  content: "\e904";
}
.icon-warning:before {
  content: "\e905";
}
.icon-long-arrow-right:before {
  content: "\e900";
}
.icon-long-arrow-left:before {
  content: "\e901";
}
.icon-close:before {
  content: "\e90b";
}
.icon-hamburger-button:before {
  content: "\e925";
}
.icon-logout:before {
  content: "\e90c";
}
.icon-check:before {
  content: "\e919";
}
.icon-plus:before {
   content: "\e102";
}
.icon-minus:before {
   content: "\ea0b";
}